import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AuthContent from 'pages/AuthContent'
import PrivateRoute from 'utils/PrivateRoute'
import { withTranslation } from 'react-i18next'

const styles = (theme) => ({
	secondaryBar: {
		zIndex: 0,
	},
})

function Authentication({ t, classes, location, pageContext }) {
	const title = t(`${pageContext.titleKey}.title`)
	return (
		<>
			<AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
				<Toolbar>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs>
							<Typography style={{ textTransform: 'capitalize' }} color="inherit" variant="h5">
								{title}
							</Typography>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<AuthContent />
		</>
	)
}
Authentication.propTypes = {
	location: PropTypes.object,
}

const AuthenticationPage = ({ location, ...rest }) => {
	return <PrivateRoute location={location} component={Authentication} {...rest} />
}

export default compose(withStyles(styles), withTranslation())(AuthenticationPage)
